<template>
  <div class="consumables">
    <div class="consumables-but">
      <el-button class="color-green" type="primary" @click="addConsumables">
        <i class="fa fa-plus-circle"></i>新增
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      stripe
      size="small"
      height="350"
      style="width: 100%"
    >
      <el-table-column prop="happen_time" label="发生时间" />
      <el-table-column prop="dict_compliaction_type" label="类别" />
      <el-table-column prop="dict_complication" label="并发症" />
      <el-table-column prop="doctor_name" label="记录人" />
      <el-table-column fixed="right" label="操作" width="120">
        <template #default="scope">
          <div class="Dsefault">
            <div
              class="Colum"
              @click="deleteRow(scope.row)"
              style="background: #3166ae"
            >
              改
            </div>
            <el-popconfirm
              title="是否确认删除?"
              @confirm="detecdele(scope.row.id)"
            >
              <template #reference>
                <span
                  class="Colum"
                  style="background: rgba(255, 62, 62, 0.1); color: #ff3e3e"
                >
                  删
                </span>
              </template>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!--分页 -->
    <div class="demo-pagination-block">
      <div></div>
      <el-pagination
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :background="true"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <!-- 修改并发症 -->
    <BaseDialog
      :title="'修改并发症'"
      :isshow="Modifiedcomplication"
      @handleShow="
        (val) => {
          Modifiedcomplication = val
        }
      "
      width="40%"
      height="auto"
    >
      <!-- 内容区 -->
      <el-form
        :inline="true"
        :rules="rules"
        :model="formInline"
        class="demo-form-inline"
      >
        <el-form-item label="类别：">
          <el-input
            placeholder="请填写"
            disabled
            v-model="formInline.dict_compliaction_type"
          />
        </el-form-item>
        <el-form-item label="名称：">
          <el-input
            placeholder="请填写"
            disabled
            v-model="formInline.dict_complication"
          />
        </el-form-item>
        <el-form-item label="发生时间：">
          <el-date-picker
            type="date"
            value-format="YYYY-MM-DD"
            v-model="formInline.happen_time"
            placeholder="请选择时间"
          />
        </el-form-item>
        <el-form-item label="记录人：" prop="name">
          <el-select placeholder="请选择" v-model="formInline.doctor_name">
            <el-option
              v-for="item in notekeeper"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 下划线 -->
      <el-divider></el-divider>

      <!-- 底部按钮区 -->
      <div class="Qinsnl">
        <div style="width: 60%"></div>
        <div class="Qinsnl">
          <div class="ider" @click="cancel">取消</div>
          <div class="vider" @click="Changessave">保存</div>
        </div>
      </div>
    </BaseDialog>
    <!-- 新增并发症 -->
    <BaseDialog
      :title="'新增并发症'"
      :isshow="addcomplication"
      @handleShow="
        (val) => {
          addcomplication = val
        }
      "
      width="60%"
      height="auto"
    >
      <!-- 内容区 -->
      <div class="addcomplication-hande">
        <!-- 左侧 -->
        <div style="width: 60%">
          <div style="height: 310px; overflow-y: scroll">
            <el-cascader-panel
              :props="handleprops"
              v-model="complicationoptions"
              @change="complicationchange"
            />
            <!-- <el-cascader-panel
              :options="props"
              v-model="complicationoptions"
              @change="complicationchange"
            /> -->
          </div>
          <el-form
            :inline="true"
            :rules="rules"
            :model="formInline"
            class="demo-form-inline"
          >
            <el-form-item label="发生时间：">
              <el-date-picker
                v-model="formInline.happen_time"
                type="date"
                placeholder="请选择"
              />
            </el-form-item>
          </el-form>
        </div>
        <!-- 右侧 -->
        <div style="38%;padding:10px">
          <div style="height: 300px; overflow-y: scroll">
            <div
              class="addcomplication-body"
              v-for="(item, index) in complist"
              :key="index"
            >
              <div class="hande">
                <div style="width: 40%">{{ item.title }}</div>
                <div class="icoun" @click="icouidele(index)">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
              <!-- 内容区 -->
              <div class="addcomplication-butom">类型：{{ item.name }}</div>
            </div>
          </div>
          <div>
            <el-form
              :inline="true"
              :rules="rules"
              :model="formInline"
              class="demo-form-inline"
            >
              <el-form-item label="记录人：" prop="doctor_id">
                <el-select
                  placeholder="请选择"
                  v-model="formInline.doctor_id"
                  @change="record"
                >
                  <el-option
                    v-for="item in notekeeper"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <!-- 底部按钮区 -->
      <div class="Qinsnl">
        <div style="width: 60%"></div>
        <div class="Qinsnl">
          <div class="ider" @click="addcomplication = false">取消</div>
          <div class="vider" @click="submitForm">保存</div>
        </div>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import BaseDialog from '@/components/Dialog/index.vue'
import { gitDictList, TimeYear } from '@/utils/tool'
import { reactive, toRefs, ref, watch, onMounted } from 'vue'
import service from '@/utils/request'
import { ElMessage,ElMessageBox } from 'element-plus'
export default {
  props: {
    patientData: {
      type: Object,
    },
  },
  components: {
    BaseDialog,
  },
  emits: ['stepsUpdate'],
  setup(props, ctx) {
    const satar = reactive({
      Modifiedcomplication: false,
      addcomplication: false,
      complicationoptions: '',
      formInline: {
        happen_time: TimeYear(new Date()),
      },
      dictList: {},
      dictListval: '',
      dict_compliaction_type: '',
      dict_complication: '',
      dict_compliaction_typelist: [],
      dict_complicationlist: [],
      list: [],
      complival: '',
      complist: [],
      notekeeper: [],
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      complicationid: '',
      total: null,
      loading: true,
      searchData: {
        patient_id: 0,
        record_id: 0,
      },
    })

    watch(
      () => props.patientData.patient_id,
      (val) => {
        if (val) {
          satar.searchData = {
            patient_id: val,
            record_id: props.patientData.record_id,
          }
        } else {
          satar.searchData = {
            patient_id: 0,
            record_id: 0,
          }
        }
        monitor()
      }
    )

    onMounted(() => {
      if (props.patientData.patient_id) {
        satar.searchData = {
          patient_id: props.patientData.patient_id,
          record_id: props.patientData.record_id,
        }
      } else {
        satar.searchData = {
          patient_id: 0,
          record_id: 0,
        }
      }
      getUserList()
      // 获取并发症列表
      monitor()
    })

    const getUserList = async () => {
      // let res = await service.post('/api/estimate/nurse')
      // if (res.code === 0) {
      //   satar.notekeeper = res.data
      // }
      satar.notekeeper = [JSON.parse(localStorage.getItem('ms_userData'))]
    }

    // 获取并发症字典
    const getDict = async () => {
      satar.dictList = await gitDictList(['206000000'])
      console.log(satar.dictList[206000000])
    }
    // 新增并发症选择
    let handleprops = ref('')
    handleprops = {
      lazy: true,
      value: 'code',
      label: 'name',
      async lazyLoad(node, resolve) {
        const { value, level } = node
        let nodes = []
        let codes = ''
        if (level === 0) {
          codes = '206000000'
        } else {
          codes = value
        }
        let res = await gitDictList([codes])
        satar.list = res[codes]
        nodes = res[codes]
        nodes.map(function (item) {
          item.leaf = level >= 1
        })
        resolve(nodes)
      },
    }
    // 新增保存
    const submitForm = async () => {
      satar.dict_complicationlist = []
      satar.dict_compliaction_typelist = []
      satar.complist.map(function (i) {
        satar.dict_complicationlist.push(i.dict_complication)
        satar.dict_compliaction_typelist.push(i.dict_compliaction_type)
      })
      const data = {
        record_id: satar.searchData.record_id,
        patient_id: satar.searchData.patient_id,
        dict_complication: satar.dict_complicationlist,
        dict_compliaction_type: satar.dict_compliaction_typelist,
        happen_time: satar.formInline.happen_time,
        doctor_id: satar.formInline.doctor_id,
      }
      let res = await service.post('api/complication/complicationCreat', data)
      if (res.code === 0) {
        ElMessage({
          message: '保存成功',
          type: 'success',
        })
        satar.addcomplication = false
        ctx.emit('stepsUpdate')
        monitor()
      }
    }

    // 病症选择
    const complicationchange = (e) => {
      const index = e[1]
      console.log(index)
      const val = e[0]
      console.log(val)
      satar.dictList[206000000].map((v) => {
        if (v.code === val) {
          satar.dictListval = v.name
          satar.dict_compliaction_type = v.code
        }
      })
      satar.list.map(function (item) {
        if (item.code === index) {
          satar.complival = item.name
          satar.dict_complication = item.code
        }
      })
      let data = {
        name: satar.complival,
        title: satar.dictListval,
        dict_compliaction_type: satar.dict_compliaction_type,
        dict_complication: satar.dict_complication,
      }
      console.log(data)
      satar.complist.push(data)
      const map = new Map()
      satar.complist = satar.complist.filter(
        (key) => !map.has(key.name) && map.set(key.name, 1)
      ) // 这里对name属性进行去重
    }

    // 删除
    const icouidele = (index) => {
      satar.complist.splice(index, 1)
    }
    // 修改
    const deleteRow = (val) => {
      satar.complicationid = val.id
      satar.formInline.dict_compliaction_type = val.dict_compliaction_type
      satar.formInline.dict_complication = val.dict_complication
      satar.Modifiedcomplication = true
    }
    const rules = reactive({
      doctor_id: [
        {
          required: true,
          message: '此项为必填',
          trigger: 'change',
        },
      ],
    })
    // 新增并发症
    const addConsumables = () => {
      if (!satar.searchData.record_id) {
          ElMessageBox.alert(
              '当前患者的透析治疗记录还没保存，不能保存透前评估！',
              '提示',
              {
                confirmButtonText: '确认',
              }
          )
          return
        }
      getDict()
      satar.complicationoptions = ''
      satar.complist = []
      satar.addcomplication = true
    }
    // 记录人选择框
    const record = (e) => {
      satar.formInline.doctor_id = e
    }
    // 获取并发症列表
    const monitor = async () => {
      satar.loading = true
      const data = {
        record_id: satar.searchData.record_id,
        patient_id: satar.searchData.patient_id,
        current: satar.currentPage,
        size: satar.pageSize,
      }
      let res = await service.post('api/complication/getList', data)
      if (res.code === 0) {
        if (res.data.records.lenght != 0) {
          satar.tableData = res.data.records
          satar.total = res.data.total
        }
        satar.loading = false
      } else {
        satar.loading = false
      }
    }
    // 当页数发生改变时
    const handleCurrentChange = (e) => {
      satar.currentPage = e
      monitor()
    }
    // 当条数发生改变时
    const handleSizeChange = (e) => {
      satar.pageSize = e
      monitor()
    }
    // 修改弹窗取消
    const cancel = () => {
      satar.Modifiedcomplication = false
    }
    // 修改保存
    const Changessave = async () => {
      let data = {
        id: satar.complicationid,
        happen_time: satar.formInline.happen_time,
        doctor_id: satar.formInline.doctor_name,
      }
      let res = await service.post('api/complication/complicationEdit', data)
      if (res.code === 0) {
        ElMessage({
          message: '保存成功',
          type: 'success',
        })
        satar.Modifiedcomplication = false
        monitor()
      }
    }
    const detecdele = async (id) => {
      let res = await service.post('api/complication/complicationDelete', {
        id: id,
      })
      if (res.code === 0) {
        ElMessage({
          message: '删除成功',
          type: 'success',
        })
        monitor()
      }
    }
    return {
      deleteRow,
      ...toRefs(satar),
      rules,
      submitForm,
      addConsumables,
      handleprops,
      record,
      complicationchange,
      getDict,
      icouidele,
      monitor,
      handleCurrentChange,
      handleSizeChange,
      cancel,
      Changessave,
      detecdele,
    }
  },
}
</script>

<style scoped lang="scss">
.consumables-but {
  text-align: right;
  margin: 10px 0;
}
.el-input-number {
  width: 130px;
}
.Dsefault {
  display: flex;
  justify-content: space-around;
}
.Dsefault span:hover {
  background: #ff3e3e !important;
  color: #fff !important;
}
.Colum {
  width: 21px;
  height: 21px;
  background: #f4a939;
  opacity: 1;
  color: #fff;
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  line-height: 21px;
}
.demo-pagination-block {
  display: flex;
  justify-content: space-between;
}
.demo-form-inline {
  text-align: center;
}
.Qinsnl {
  display: flex;
  justify-content: space-between;
}
.ider {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #3166ae;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #3166ae;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  margin: 0 20px;
}
.vider {
  width: 80px;
  height: 32px;
  background: #3166ae;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #3166ae;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
}
.addcomplication-hande {
  margin-top: 20px;
  display: flex;
  height: 400px;
}
.addcomplication-body {
  width: 255px;
  height: 104px;
  background: #ffffff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin-left: 10px;
  margin: 14px 0 14px 10px;
  .hande {
    height: 50px;
    align-items: center;
    background: #3166ae;
    border-radius: 8px 8px 0px 0px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #ffffff;
    padding: 7px;
  }
  .icoun {
    width: 18px;
    height: 18px;
    background: #ffffff;
    opacity: 1;
    border-radius: 50%;
    text-align: center;
    padding-bottom: 10px;
    color: #c4c4c4;
    cursor: pointer;
  }
}
.addcomplication-butom {
  display: flex;
  margin: 10px 0 0 20px;
}
.el-cascader-panel.is-bordered {
  border: none !important;
}
.demo-form-inline {
  margin-top: 20px;
}
</style>
